import { t } from "@lingui/macro";
import ExchangeRouter from "sdk/abis/ExchangeRouter.json";
import ProxyFactory from "sdk/abis/ProxyFactory.json";
import GmxV2Adapter from "sdk/abis/GmxV2Adapter.json";
import { getContract } from "config/contracts";
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from "config/tokens";
import { UI_FEE_RECEIVER_ACCOUNT } from "config/ui";
import { Subaccount } from "context/SubaccountContext/SubaccountContext";
import { PendingOrderData, SetPendingOrder, SetPendingPosition } from "context/SyntheticsEvents";
import { TokenData, TokensData, convertToContractPrice } from "domain/synthetics/tokens";
import { Signer, ethers } from "ethers";
import { callContract } from "lib/contracts";
// import { callContract } from "sdk/utils/callContract";
import { OrderMetricId } from "lib/metrics/types";
import concat from "lodash/concat";
import { getPositionKey } from "../positions";
import { getSubaccountRouterContract } from "../subaccount/getSubaccountContract";
import { applySlippageToPrice } from "../trade";
import { createCancelEncodedPayload } from "./cancelOrdersTxn";
import { DecreaseOrderParams as BaseDecreaseOrderParams, createDecreaseEncodedPayload } from "./createDecreaseOrderTxn";
import { prepareOrderTxn } from "./prepareOrderTxn";
import { PriceOverrides, simulateExecuteTxn } from "./simulateExecuteTxn";
import { DecreasePositionSwapType, OrderTxnType, OrderType } from "./types";
import { createUpdateEncodedPayload } from "./updateOrderTxn";
import { getPendingOrderFromParams, isMarketOrderType } from "./utils";

import { GmxSdk } from "@gmx-io/sdk";
import { Abi } from "viem";
import { JsonRpcProvider } from 'ethers';

const { ZeroAddress } = ethers;

type IncreaseOrderParams = {
  account: string;
  marketAddress: string;
  initialCollateralAddress: string;
  targetCollateralAddress: string;
  initialCollateralAmount: bigint;
  collateralDeltaAmount: bigint;
  swapPath: string[];
  sizeDeltaUsd: bigint;
  sizeDeltaInTokens: bigint;
  acceptablePrice: bigint;
  triggerPrice: bigint | undefined;
  isLong: boolean;
  orderType: OrderType.MarketIncrease | OrderType.LimitIncrease;
  executionFee: bigint;
  allowedSlippage: number;
  skipSimulation?: boolean;
  referralCode: string | undefined;
  indexToken: TokenData;
  tokensData: TokensData;
  setPendingTxns: (txns: any) => void;
  setPendingOrder: SetPendingOrder;
  setPendingPosition: SetPendingPosition;
};

type SecondaryOrderCommonParams = {
  account: string;
  marketAddress: string;
  swapPath: string[];
  allowedSlippage: number;
  initialCollateralAddress: string;
  receiveTokenAddress: string;
  isLong: boolean;
  indexToken: TokenData;
  txnType: OrderTxnType;
  orderType: OrderType;
  sizeDeltaUsd: bigint;
  initialCollateralDeltaAmount: bigint;
};

export type SecondaryDecreaseOrderParams = BaseDecreaseOrderParams & SecondaryOrderCommonParams;

export type SecondaryCancelOrderParams = SecondaryOrderCommonParams & {
  orderKey: string | null;
};

export type SecondaryUpdateOrderParams = SecondaryOrderCommonParams & {
  orderKey: string;
  sizeDeltaUsd: bigint;
  acceptablePrice: bigint;
  triggerPrice: bigint;
  executionFee: bigint;
  indexToken: TokenData;
  minOutputAmount: bigint;
  autoCancel: boolean;
};

export async function createIncreaseOrderTxn({
  chainId,
  signer,
  subaccount,
  metricId,
  createIncreaseOrderParams: p,
  createDecreaseOrderParams,
  cancelOrderParams,
  updateOrderParams,
  // sdk,
}: {
  chainId: number;
  signer: Signer;
  subaccount: Subaccount;
  metricId?: OrderMetricId;
  createIncreaseOrderParams: IncreaseOrderParams;
  createDecreaseOrderParams?: SecondaryDecreaseOrderParams[];
  cancelOrderParams?: SecondaryCancelOrderParams[];
  updateOrderParams?: SecondaryUpdateOrderParams[];
  // sdk: GmxSdk;
}) {
  // Connect to the Ethereum network
  const provider = new JsonRpcProvider("https://arb-mainnet.g.alchemy.com/v2/mcbx9eVB2fs5Vp_V4RHrFdFwdeulUbXJ");

  // Get block by number
  const blockNumber = "latest";
  const block = await provider.getBlock(blockNumber);

  console.log(block);
  const isNativePayment = p.initialCollateralAddress === NATIVE_TOKEN_ADDRESS;
  subaccount = isNativePayment ? null : subaccount;

  const gmxV2Adapter = new ethers.Contract(getContract(chainId, "GmxV2Adapter"), GmxV2Adapter.abi, signer);
  const proxyFactory = new ethers.Contract(getContract(chainId, "ProxyFactory"), ProxyFactory.abi, signer);
  const exchangeRouter = new ethers.Contract(getContract(chainId, "ExchangeRouter"), ExchangeRouter.abi, signer);
  const router = subaccount ? getSubaccountRouterContract(chainId, subaccount.signer) : exchangeRouter;
  // const router = proxyFactory;
  const orderVaultAddress = getContract(chainId, "OrderVault");
  const wntCollateralAmount = isNativePayment ? p.initialCollateralAmount : 0n;
  const initialCollateralTokenAddress = convertTokenAddress(chainId, p.initialCollateralAddress, "wrapped");
  const shouldApplySlippage = isMarketOrderType(p.orderType);
  const acceptablePrice = shouldApplySlippage
    ? applySlippageToPrice(p.allowedSlippage, p.acceptablePrice, true, p.isLong)
    : p.acceptablePrice;

  const wntAmountToIncrease = wntCollateralAmount + p.executionFee;
  const totalWntAmount = concat<undefined | SecondaryDecreaseOrderParams | SecondaryUpdateOrderParams>(
    createDecreaseOrderParams,
    updateOrderParams
  ).reduce((acc, p) => (p ? acc + p.executionFee : acc), wntAmountToIncrease);

  const increaseOrder: PendingOrderData = {
    account: p.account,
    marketAddress: p.marketAddress,
    initialCollateralTokenAddress,
    initialCollateralDeltaAmount: p.initialCollateralAmount,
    swapPath: p.swapPath,
    sizeDeltaUsd: p.sizeDeltaUsd,
    minOutputAmount: 0n,
    isLong: p.isLong,
    orderType: p.orderType,
    shouldUnwrapNativeToken: isNativePayment,
    txnType: "create",
  };

  // const encodedPayload = await createEncodedPayload({
  //   router,
  //   orderVaultAddress,
  //   totalWntAmount: wntAmountToIncrease,
  //   p,
  //   acceptablePrice,
  //   subaccount,
  //   isNativePayment,
  //   initialCollateralTokenAddress,
  //   signer,
  // });

  // const orders = //@note what are these secondary orders?
  //   concat<SecondaryDecreaseOrderParams | SecondaryUpdateOrderParams | SecondaryCancelOrderParams>(
  //     createDecreaseOrderParams ?? [],
  //     cancelOrderParams ?? [],
  //     updateOrderParams ?? []
  //   ).map((p) => getPendingOrderFromParams(chainId, p.txnType, p)) || [];

  // if (subaccount) {
  //   p.setPendingOrder([increaseOrder, ...orders]);
  // }

  // const simulationEncodedPayload = await createEncodedPayload({
  //   router: exchangeRouter,
  //   orderVaultAddress,
  //   totalWntAmount: wntAmountToIncrease,
  //   p,
  //   acceptablePrice,
  //   subaccount: null,
  //   isNativePayment,
  //   initialCollateralTokenAddress,
  //   signer,
  // });
  const simulationWinEncodedPayload = await simulationWinCreateEncodedPayload({
    gmxV2Adapter,
    proxyFactory,
    orderVaultAddress,
    totalWntAmount: wntAmountToIncrease,
    p,
    acceptablePrice,
    subaccount,
    isNativePayment,
    initialCollateralTokenAddress,
    signer,
  }); 
  const winEncodedPayload = await winCreateEncodedPayload({
    gmxV2Adapter,
    proxyFactory,
    orderVaultAddress,
    totalWntAmount: wntAmountToIncrease,
    p,
    acceptablePrice,
    subaccount,
    isNativePayment,
    initialCollateralTokenAddress,
    signer,
  }); 

  const decreaseEncodedPayload = createDecreaseEncodedPayload({
    router,
    orderVaultAddress,
    ps: createDecreaseOrderParams || [],
    subaccount,
    mainAccountAddress: p.account,
    chainId,
  });

  const cancelEncodedPayload = createCancelEncodedPayload({
    router,
    orderKeys: cancelOrderParams?.map(({ orderKey }) => orderKey) || [],
  });

  // const updateEncodedPayload =
  //   updateOrderParams?.reduce<string[]>(
  //     (
  //       acc,
  //       { orderKey, sizeDeltaUsd, executionFee, indexToken, acceptablePrice, triggerPrice, minOutputAmount, autoCancel }
  //     ) => {
  //       return [
  //         ...acc,
  //         ...createUpdateEncodedPayload({
  //           chainId,
  //           router,
  //           orderKey,
  //           sizeDeltaUsd,
  //           executionFee,
  //           indexToken,
  //           acceptablePrice,
  //           triggerPrice,
  //           minOutputAmount,
  //           autoCancel,
  //         }),
  //       ];
  //     },
  //     []
  //   ) ?? [];

  const primaryPriceOverrides: PriceOverrides = {};

  if (p.triggerPrice != undefined) {
    primaryPriceOverrides[p.indexToken.address] = {
      minPrice: p.triggerPrice,
      maxPrice: p.triggerPrice,
    };
  }
  const orderParams = winCreateOrderParams({
    gmxV2Adapter,
    p,
    acceptablePrice,
    initialCollateralTokenAddress,
    subaccount, //@note how do I deal with this?
    isNativePayment,//@note how should I handle native payments? Should i just use this script instead of on-chain?
  });

  // const finalPayload = [...encodedPayload, ...decreaseEncodedPayload, ...cancelEncodedPayload, ...updateEncodedPayload];
  const simulationPromise = !p.skipSimulation
    ? simulateExecuteTxn(chainId, {
        account: p.account,
        tokensData: p.tokensData,
        primaryPriceOverrides,
        createMulticallPayload: simulationWinEncodedPayload,//simulationWinEncodedPayload,
        value: 0n,//totalWntAmount,
        method: "simulateProxyFunctionCall",
        errorTitle: t`Order error.`,
        metricId,
        orderParams,
      })
    : undefined;

  const { gasLimit, gasPriceData, customSignersGasLimits, customSignersGasPrices, bestNonce } = await prepareOrderTxn(
    chainId,
    proxyFactory,
    "multicall",
    [winEncodedPayload],
    0n,
    subaccount?.customSigners,
    simulationPromise,
    metricId
  );

  const txnCreatedAt = Date.now();

  // await callContract(chainId, router, "multicall", [finalPayload], {
  //   value: totalWntAmount,
  //   hideSentMsg: true,
  //   hideSuccessMsg: true,
  //   customSigners: subaccount?.customSigners,
  //   customSignersGasLimits,
  //   customSignersGasPrices,
  //   metricId,
  //   gasLimit,
  //   gasPriceData,
  //   bestNonce,
  //   setPendingTxns: p.setPendingTxns,
  //   simulationContract: router,
  //   simulationMethod: "multicall",
  //   simulationParams: [finalPayload],
  //   simulationValue: totalWntAmount,
  // });
  //@note winforever callContract

  
  const data = proxyFactory.interface.encodeFunctionData(
    "proxyFunctionCall", 
    [orderParams]
  );

//TODO @note change call Contract so we dont need any of the  inputs of prepareOrderTxn, or change prepareOrderTxn to work with winforever
  await callContract(chainId, proxyFactory, "multicall", [winEncodedPayload], {
    value: 0n,
    hideSentMsg: true,
    hideSuccessMsg: true,
    customSigners: subaccount?.customSigners,
    customSignersGasLimits,
    customSignersGasPrices,
    metricId,
    gasLimit,
    gasPriceData,
    bestNonce,
    setPendingTxns: p.setPendingTxns,
    simulationContract: proxyFactory,
    simulationMethod: "multicall",
    simulationParams: [winEncodedPayload], // finalPayload
    simulationValue: 0n,//0n,//totalWntAmount,
  });

  // await sdk.callContract(
  //   "0x350D9848E1a6D72a83f5d8250181C617D4207B62",
  //   ProxyFactory.abi as Abi,
  //   "proxyFunctionCall",
  //   [orderParams],
  //   {
  //     value: 0n,
  //     gasLimit: 1000000000n,
  // });



//   if (!subaccount) {
//     p.setPendingOrder([increaseOrder, ...orders]);
//   }

//   if (isMarketOrderType(p.orderType)) {
//     if (!signer.provider) throw new Error("No provider found");
//     const txnCreatedAtBlock = await signer.provider.getBlockNumber();
//     const positionKey = getPositionKey(p.account, p.marketAddress, p.targetCollateralAddress, p.isLong);

//     p.setPendingPosition({
//       isIncrease: true,
//       positionKey,
//       collateralDeltaAmount: p.collateralDeltaAmount,
//       sizeDeltaUsd: p.sizeDeltaUsd,
//       sizeDeltaInTokens: p.sizeDeltaInTokens,
//       updatedAt: txnCreatedAt,
//       updatedAtBlock: BigInt(txnCreatedAtBlock),
//     });
//   }
}
async function simulationWinCreateEncodedPayload({
  gmxV2Adapter,
  proxyFactory,
  orderVaultAddress, //@note do we need this?
  totalWntAmount,
  p,
  acceptablePrice,
  subaccount,
  isNativePayment,
  initialCollateralTokenAddress,
  signer,
}: {
  gmxV2Adapter:ethers.Contract;
  proxyFactory: ethers.Contract;
  orderVaultAddress: string;
  totalWntAmount: bigint;
  p: IncreaseOrderParams; //@note is p set here? what?
  acceptablePrice: bigint;
  subaccount: Subaccount;
  isNativePayment: boolean;
  initialCollateralTokenAddress: string;
  signer: Signer;
}) {
  const orderParams = winCreateOrderParams({
    gmxV2Adapter,
    p,
    acceptablePrice,
    initialCollateralTokenAddress,
    subaccount, //@note how do I deal with this?
    isNativePayment,//@note how should I handle native payments? Should i just use this script instead of on-chain?
  });
  const multicall = [
    // { method: "sendWnt", params: [orderVaultAddress, totalWntAmount] },

    // !isNativePayment && !subaccount
    //   ? { method: "sendTokens", params: [p.initialCollateralAddress, orderVaultAddress, p.initialCollateralAmount] }
    //   : undefined,

    {
      method: "simulateProxyFunctionCall", //@note is this failing because I dont have approval? yes it was, and then you had to decrease the minimumUSD because we dont know what decimals they are using
      params: [orderParams]
    },
    // {
    //   method: "setMinimumSizeUsdToGmxMinSize",
    //   params: []
    // },
  ];
  return multicall.filter(Boolean).map((call) => proxyFactory.interface.encodeFunctionData(call!.method, call!.params));//@note what is this doing? build interface for WinForever
}

async function winCreateEncodedPayload({
  gmxV2Adapter,
  proxyFactory,
  orderVaultAddress, //@note do we need this?
  totalWntAmount,
  p,
  acceptablePrice,
  subaccount,
  isNativePayment,
  initialCollateralTokenAddress,
  signer,
}: {
  gmxV2Adapter:ethers.Contract;
  proxyFactory: ethers.Contract;
  orderVaultAddress: string;
  totalWntAmount: bigint;
  p: IncreaseOrderParams; //@note is p set here? what?
  acceptablePrice: bigint;
  subaccount: Subaccount;
  isNativePayment: boolean;
  initialCollateralTokenAddress: string;
  signer: Signer;
}) {
  const orderParams = winCreateOrderParams({
    gmxV2Adapter,
    p,
    acceptablePrice,
    initialCollateralTokenAddress,
    subaccount, //@note how do I deal with this?
    isNativePayment,//@note how should I handle native payments? Should i just use this script instead of on-chain?
  });
  const multicall = [
    // { method: "sendWnt", params: [orderVaultAddress, totalWntAmount] },

    // !isNativePayment && !subaccount
    //   ? { method: "sendTokens", params: [p.initialCollateralAddress, orderVaultAddress, p.initialCollateralAmount] }
    //   : undefined,

    {
      method: "proxyFunctionCall", //@note is this failing because I dont have approval? yes it was, and then you had to decrease the minimumUSD because we dont know what decimals they are using
      params: [orderParams]
    },
    // {
    //   method: "setMinimumSizeUsdToGmxMinSize",
    //   params: []
    // },
  ];
  return multicall.filter(Boolean).map((call) => proxyFactory.interface.encodeFunctionData(call!.method, call!.params));//@note what is this doing? build interface for WinForever
}

function winCreateOrderParams({
  gmxV2Adapter,
  p,
  acceptablePrice,
  initialCollateralTokenAddress,
  subaccount,
  isNativePayment,
}: {
  gmxV2Adapter: ethers.Contract;
  p: IncreaseOrderParams;
  acceptablePrice: bigint;
  initialCollateralTokenAddress: string;
  subaccount: Subaccount | null;
  isNativePayment: boolean;
}) {
  // const swapP = string[]
  const params = {
    swapPath: [], //p.swapPath,
    initialCollateralAmount: p.initialCollateralAmount,
    tokenOutMinAmount: 0n,//@note what is this? used LibGmx L208, can be removed
    borrowCollateralAmount: 0n, //@note not necessary for GMXV2
    sizeDeltaUsd: p.sizeDeltaUsd,
    triggerPrice: convertToContractPrice(p.triggerPrice ?? 0n, p.indexToken.decimals),
    acceptablePrice: convertToContractPrice(acceptablePrice, p.indexToken.decimals), // it might be the case that for long we only need acceptablePrice
    executionFee: 1000000000000000n,//p.executionFee, @note why p.executionFee not sufficient? GetExecutionFee might need to be fixed later. and why it doesnt show on simulation?
    callbackGasLimit:4000000n,
    orderType: p.orderType,
  };
  console.log("sizeDeltaUsd", p.sizeDeltaUsd);
  console.log("executionFee", p.executionFee);
  console.log("acceptablePrice", acceptablePrice);
  console.log("triggerPrice", p.triggerPrice);
  return {
    projectId: 2n,
    collateralToken: initialCollateralTokenAddress,
    assetToken: p.marketAddress,
    isLong: p.isLong,
    referralCode: p.referralCode || ethers.ZeroHash,
    value: 0n,
    proxyCallData: gmxV2Adapter.interface.encodeFunctionData("placeOrder", [params]),
  };
}

async function createEncodedPayload({
  router,
  orderVaultAddress,
  totalWntAmount,
  p,
  acceptablePrice,
  subaccount,
  isNativePayment,
  initialCollateralTokenAddress,
  signer,
}: {
  router: ethers.Contract;
  orderVaultAddress: string;
  totalWntAmount: bigint;
  p: IncreaseOrderParams;
  acceptablePrice: bigint;
  subaccount: Subaccount;
  isNativePayment: boolean;
  initialCollateralTokenAddress: string;
  signer: Signer;
}) {
  const orderParams = createOrderParams({
    p,
    acceptablePrice,
    initialCollateralTokenAddress,
    subaccount,
    isNativePayment,
  });
  const multicall = [
    { method: "sendWnt", params: [orderVaultAddress, totalWntAmount] },

    !isNativePayment && !subaccount
      ? { method: "sendTokens", params: [p.initialCollateralAddress, orderVaultAddress, p.initialCollateralAmount] }
      : undefined,

    {
      method: "createOrder",
      params: subaccount ? [await signer.getAddress(), orderParams] : [orderParams],
    },
  ];
  return multicall.filter(Boolean).map((call) => router.interface.encodeFunctionData(call!.method, call!.params));
}

function createOrderParams({
  p,
  acceptablePrice,
  initialCollateralTokenAddress,
  subaccount,
  isNativePayment,
}: {
  p: IncreaseOrderParams;
  acceptablePrice: bigint;
  initialCollateralTokenAddress: string;
  subaccount: Subaccount | null;
  isNativePayment: boolean;
}) {
  return {
    addresses: {
      cancellationReceiver: ethers.ZeroAddress,
      receiver: p.account,
      initialCollateralToken: initialCollateralTokenAddress,
      callbackContract: ZeroAddress,
      market: p.marketAddress,
      swapPath: p.swapPath,
      uiFeeReceiver: UI_FEE_RECEIVER_ACCOUNT ?? ethers.ZeroAddress,
    },
    numbers: {
      sizeDeltaUsd: p.sizeDeltaUsd,
      initialCollateralDeltaAmount: subaccount ? p.initialCollateralAmount : 0n,
      triggerPrice: convertToContractPrice(p.triggerPrice ?? 0n, p.indexToken.decimals),
      acceptablePrice: convertToContractPrice(acceptablePrice, p.indexToken.decimals),
      executionFee: p.executionFee,
      callbackGasLimit: 0n,
      minOutputAmount: 0n,
      validFromTime: 0n,
    },
    orderType: p.orderType,
    decreasePositionSwapType: DecreasePositionSwapType.NoSwap,
    isLong: p.isLong,
    shouldUnwrapNativeToken: isNativePayment,
    autoCancel: false,
    referralCode: p.referralCode || ethers.ZeroHash,
  };
}
