import { useCompetitionData } from "domain/winforevs/competition/useCompetitionData";
import { useLeaderboardData } from "domain/winforevs/leaderboard";
import { useSelector } from "../utils";
import { selectAccount } from "../selectors/globalSelectors";
import { selectChainId } from "../selectors/globalSelectors";
import { formatAmount, formatAmountFree, formatUsd, parseValue } from "lib/numbers";
import {getProxyAddresses, usePositions} from "domain/winforevs/positions/usePositions";
import { useSettings } from "context/SettingsContext/SettingsContextProvider";
import { usePositionsInfoRequest } from "domain/winforevs/positions/usePositionsInfo";
import { useMarketsInfoRequest } from "domain/winforevs/markets/useMarketsInfoRequest";
import { useTokensDataRequest } from "domain/winforevs/tokens/useTokensDataRequest";
import { useMarkets } from "domain/winforevs/markets/useMarkets";
import React from "react";
import { useAllPositionsResult, usePositionsInfoData } from "./globalsHooks";
import { convertToUsd } from "sdk/utils/tokens";
import { EMPTY_ARRAY, getByKey } from "lib/objects";
import {useAllPositions} from "domain/winforevs/positions/useAllPositions"


export const useCompetitionStats = () => {
  const chainId = useSelector(selectChainId);
  const account = useSelector(selectAccount);
  const  {competitionData}  = useCompetitionData(chainId, account);
  // const contenderAccounts = competitionData?.contenders.map(contender => contender.owner) || [];
  // const positionsResult = useAllPositions(chainId, {
  //   accounts: contenderAccounts,
  //   marketsData: markets.marketsData,
  //   tokensData,
  // });
              // convert unixtimestamp to seconds
  const currentTime = Math.floor(new Date().getTime() / 1000);
  // console.log("currentTime", currentTime);
  const epochEndTime = Number(competitionData?.epochEndTime);
  // console.log("epochEndTime", epochEndTime);
  const timeRemaining = epochEndTime - currentTime;
  const formatTimeRemaining = (seconds: number) => {
    if (seconds != Number(seconds)) {
      return "Connect Wallet";
    }
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;
    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };
  // console.log("timeRemaining", timeRemaining);
  const competitionFinished = timeRemaining <= 0 ? "Competition Finished" : ("Competition Countdown: " + formatTimeRemaining(timeRemaining));
  const totalCurrentPrize = formatAmount(competitionData?.totalCurrentPrize, 6, 2);
  const contenders = competitionData?.contenders.slice(1);

  // get contender that matches account
  let AccountRoi;
  let totalCompPrize = 0;
  let leaderboardArray = [];
  let totalUsdPnl = 0;
  // const positionsInfoData = usePositionsInfoData();
  // console.log("positionsInfoData", positionsInfoData);
  // console.log("account", account);
  const allPositionsInfoData = useAllPositionsResult();
  // console.log("allPositionsInfoData", allPositionsInfoData);

  // go throug contenders and add pendingPnl to realizedPnl
  // calculate roi
  // add to sortedContenders

  competitionData?.contenders.forEach((contender: any) => {
    let pendingPnl = 0;
    allPositionsInfoData.forEach((positionInfo, address) => {
      if (contender.owner === address) {    
        Object.values(positionInfo).forEach((position) => {
          // console.log("position", position);
          const pnlAfterFees = position.pnlAfterFees;
          // console.log("pnlAfterFees", position.pnlAfterFees);
          const convertedPnl = Number(formatAmount(pnlAfterFees, 30, 6));
          // console.log("convertedPnl", convertedPnl);
          pendingPnl += convertedPnl;
          // console.log("pendingPnl", pendingPnl);
        });
      }
    });
    const realizedPnl = contender.pnl;
    // console.log("realizedPnl", realizedPnl);
    // format realizedPnl to 3 decimals
    const formattedRealizedPnl = Number(formatAmount(realizedPnl, 6, 6));
    // console.log("formattedRealizedPnl", formattedRealizedPnl);
    const totalPnl = pendingPnl + formattedRealizedPnl;
    
    // console.log("totalPnl", totalPnl);
    totalCompPrize += totalPnl;
    console.log("totalCompPrize", totalCompPrize);
    const collateral = formatAmount(contender.collateral, 6, 6);
    // console.log("collateral", collateral);
    // const totalPnl = Number(pnlAfterFees) + realizedPnl;
    const roi = (Number(totalPnl) / Number(collateral));// * 100n;
    // console.log("roi", roi);
    // AccountRoi = Number(roi + totalPnlAfterFeesPercentageDecimal ) ; 
    if (contender.owner === account) {
      AccountRoi = roi.toFixed(4); 
      // console.log("AccountRoi", AccountRoi);
      // AccountRoi = formatAmount(AccountRoi, 6, 5);
    }
    //map contender to roi
    contender.roi = roi;
  });
  // console.log("AccountRoi", AccountRoi);

  // merge sort contenders by roi
  const sortedContenders = Array.isArray(contenders) ? contenders.sort((a: any, b: any) => 
    Number(b.roi) - Number(a.roi)
  ) : contenders;
  // console.log("sortedContenders", sortedContenders); 

  const placement = contenders?.findIndex((contender) => contender.owner === account) + 1;
  // console.log("placement", placement);

  return {
    timeRemaining: competitionFinished,
    sortedContenders,
    AccountRoi,
    totalCompPrize,
    placement,
    account,
  };
}; 