import { useEffect, useMemo } from "react";
import { useMulticall } from "lib/multicall";
import { getContract } from "config/contracts";
import { FREQUENT_MULTICALL_REFRESH_INTERVAL } from "lib/timeConstants";
import ProxyFactory from "sdk/abis/ProxyFactory.json";
import { bigintToNumber } from "lib/numbers";
import { resolve } from "path/win32";

type CompetitionResult = {
  competitionData?: {
    epochEndTime: any;
    totalCurrentPrize: any;
    contenders: any;
  };
//   error?: Error;
};

export function useCompetitionData(chainId: number, account: string | null | undefined): CompetitionResult {
  const { data } = useMulticall(chainId, "useCompetitionData", {
    key: account ? [account] : null,
    refreshInterval: FREQUENT_MULTICALL_REFRESH_INTERVAL,
    request: () => ({
      proxyFactory: {
        contractAddress: getContract(chainId, "ProxyFactory"),
        abi: ProxyFactory.abi,
        calls: {
          currentEpoch: {
            methodName: "viewCurrentEpoch",
            params: [],
          },
          contenders: {
            methodName: "viewEpochContenders",
            params: [],
          }
        },
      },
    }),
    parseResponse: (res) => {
        // console.log("Contenders response", res.data.proxyFactory.contenders.returnValues);
        // console.log("Current Epoch response", res.data.proxyFactory.currentEpoch.returnValues);

    //   const currentEpoch = res.data.proxyFactory.currentEpoch.returnValues;
      const contenders = res.data.proxyFactory.contenders.returnValues;
      const epochEndTime = res.data.proxyFactory.currentEpoch.returnValues[1];
      const totalCurrentPrize = Number(BigInt(res.data.proxyFactory.currentEpoch.returnValues[2]));
      

      return {
        epochEndTime,
        totalCurrentPrize,
        contenders,
      };
    },
  });

  return {
    competitionData: data,
    // error
  };
}