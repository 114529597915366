import { t } from "@lingui/macro";
import { ApproveTokenButton } from "components/ApproveTokenButton/ApproveTokenButton";
import { HighPriceImpactWarning } from "components/Synthetics/HighPriceImpactWarning/HighPriceImpactWarning";
import { getContract } from "config/contracts";
import { useTokensData } from "context/SyntheticsStateContext/hooks/globalsHooks";
import {
  selectTradeboxExecutionFee,
  selectTradeboxFromTokenAddress,
  selectTradeboxIncreasePositionAmounts,
  selectTradeboxIsWrapOrUnwrap,
  selectTradeboxSwapAmounts,
  selectTradeboxTradeFlags,
} from "context/SyntheticsStateContext/selectors/tradeboxSelectors";
import { useSelector } from "context/SyntheticsStateContext/utils";
import { getNeedTokenApprove, useTokensAllowanceData } from "domain/winforevs/tokens";
import { useHighExecutionFeeConsent } from "domain/winforevs/trade/useHighExecutionFeeConsent";
import { usePriceImpactWarningState } from "domain/winforevs/trade/usePriceImpactWarningState";
import { useChainId } from "lib/chains";
import { getByKey } from "lib/objects";
import { useMemo } from "react";

export function useTradeboxWarningsRows(priceImpactWarningState: ReturnType<typeof usePriceImpactWarningState>) {
  const WETH_ADDRESS = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  const USDC_ADDRESS = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
  const tokenData = useTokensData();
  const { chainId } = useChainId();
  const fromTokenAddress = useSelector(selectTradeboxFromTokenAddress);
  const tradeFlags = useSelector(selectTradeboxTradeFlags);
  const fromToken = getByKey(tokenData, fromTokenAddress);
  const fromWETH = getByKey(tokenData, WETH_ADDRESS);
  const fromUSDC = getByKey(tokenData, USDC_ADDRESS);
  const increaseAmounts = useSelector(selectTradeboxIncreasePositionAmounts);
  const isWrapOrUnwrap = useSelector(selectTradeboxIsWrapOrUnwrap);
  const swapAmounts = useSelector(selectTradeboxSwapAmounts);
  const executionFee = useSelector(selectTradeboxExecutionFee);
  //@note TODO change how we fetch these, use already existing fetchers
  
  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    // spenderAddress: getContract(chainId, "SyntheticsRouter"),
    spenderAddress: getContract(chainId, "ProxyFactory"),
    tokenAddresses: fromToken ? [fromToken.address, 
      fromWETH?.address ?? WETH_ADDRESS,
      fromUSDC?.address ?? USDC_ADDRESS,
    ]
    : [
      fromWETH?.address ?? WETH_ADDRESS,
      fromUSDC?.address ?? USDC_ADDRESS,
    ],
  });

  const { isSwap, isIncrease } = tradeFlags;

  const payAmount = useMemo(() => {
    if (isSwap && !isWrapOrUnwrap) {
      return swapAmounts?.amountIn;
    }
    if (isIncrease) {
      return increaseAmounts?.initialCollateralAmount;
    }
  }, [increaseAmounts?.initialCollateralAmount, isIncrease, isSwap, isWrapOrUnwrap, swapAmounts?.amountIn]);

  const needPayTokenApproval =
    tokensAllowanceData &&
    fromToken &&
    payAmount !== undefined &&
    getNeedTokenApprove(tokensAllowanceData, fromToken.address, payAmount);

  //@note Deprecate maxUint approval in the future?
  const maxUint256 = BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
  const needWETHApproval =
    tokensAllowanceData &&
    fromWETH &&
    payAmount !== undefined &&
    getNeedTokenApprove(tokensAllowanceData, fromWETH.address, maxUint256);

  const needUSDCApproval =
    tokensAllowanceData &&
    fromUSDC &&
    payAmount !== undefined &&
    getNeedTokenApprove(tokensAllowanceData, fromUSDC.address, maxUint256);

  const { element: highExecutionFeeAcknowledgement, isHighFeeConsentError } = useHighExecutionFeeConsent(
    executionFee?.feeUsd
  );

  const consentError: string | undefined = useMemo(() => {
    if (highExecutionFeeAcknowledgement && isHighFeeConsentError) {
      return t`High Network Fee not yet acknowledged`;
    }

    if (
      (priceImpactWarningState.shouldShowWarningForPosition && !priceImpactWarningState.isHighPositionImpactAccepted) ||
      (priceImpactWarningState.shouldShowWarningForSwap && !priceImpactWarningState.isHighSwapImpactAccepted)
    ) {
      return t`Price Impact not yet acknowledged`;
    }

    if (needPayTokenApproval && fromToken) {
      return t`Pending ${fromToken?.assetSymbol ?? fromToken?.symbol} approval`;
    }

    return undefined;
  }, [
    fromToken,
    needPayTokenApproval,
    priceImpactWarningState,
    highExecutionFeeAcknowledgement,
    isHighFeeConsentError,
  ]);

  const element = (
    <>
      <HighPriceImpactWarning priceImpactWarningState={priceImpactWarningState} />
      {highExecutionFeeAcknowledgement}
      {/* {needPayTokenApproval && fromToken && (
        <ApproveTokenButton
          tokenAddress={fromToken.address}
          tokenSymbol={fromToken.assetSymbol ?? fromToken.symbol}
          // spenderAddress={getContract(chainId, "SyntheticsRouter")}
          spenderAddress={getContract(chainId, "ProxyFactory")}
        />
      )} */}
      
      {/* FETCH USDC AND APPROVAL STATE, PRESENT BUTTON IF NEEDED */}
      {!needUSDCApproval || !needWETHApproval && (
        <span className="text-body-small text-blue-550"> 
          Please ensure to approve both WETH and USDC before trading.
        </span>
      )}
      {needWETHApproval && fromWETH && (
        <ApproveTokenButton
          tokenAddress={fromWETH.address ?? WETH_ADDRESS}
          tokenSymbol={fromWETH.assetSymbol ?? fromWETH.symbol}
          spenderAddress={getContract(chainId, "ProxyFactory")}
        />
      )}
      {needUSDCApproval && (
        <ApproveTokenButton
          tokenAddress={fromUSDC.address ?? USDC_ADDRESS}
          tokenSymbol={fromUSDC.assetSymbol ?? fromUSDC.symbol}
          spenderAddress={getContract(chainId, "ProxyFactory")}
        />
      )}
      
    </>
  );

  return [element, consentError] as const;
}
