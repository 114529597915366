import './CompetitionRight.scss';
import { useCompetitionStats } from '../../../context/SyntheticsStateContext/hooks/competitionHooks';
import { useMemo, useRef, useEffect } from 'react';

function truncateAddress(address: string): string {
  if (!address || address.length <= 6) return address || '';
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
}

export function CompetitionRight() {
  const { sortedContenders = [], account } = useCompetitionStats();
  const tableRef = useRef<HTMLTableElement>(null);
  
  const sortedAndHighlightedContenders = useMemo(() => {
    if (!account || !sortedContenders?.length) return sortedContenders || [];
    
    const userIndex = sortedContenders.findIndex(
      (contender) => contender.owner.toLowerCase() === account.toLowerCase()
    );
    
    if (userIndex === -1) return sortedContenders;
    
    const reorderedContenders = [...sortedContenders];
    const userEntry = reorderedContenders.splice(userIndex, 1)[0];
    reorderedContenders.unshift(userEntry);
    
    // Limit to 10 entries
    return reorderedContenders.slice(0, 10);
  }, [account, sortedContenders]);

  // Share height with CompetitionLeft
  useEffect(() => {
    if (tableRef.current) {
      const height = tableRef.current.offsetHeight;
      document.documentElement.style.setProperty('--competition-table-height', `${height}px`);
    }
  }, [sortedAndHighlightedContenders]);
  console.log(account);
  if (!account) {
    return (
      <div className="competition-right-container">
        <div>Please connect your wallet to view the leaderboard</div>
      </div>
    );
  } else if (!sortedContenders?.length) {
    return (
      <div className="competition-right-container">
        <div>Loading leaderboard data...</div>
      </div>
    );
  }  

  return (
    <div className="competition-right-container">
      <table ref={tableRef} className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank
              {sortedAndHighlightedContenders.map((contender, index) => {
                const originalIndex = sortedContenders.findIndex(
                  (c) => c.owner === contender.owner
                );
                const isUser = contender.owner.toLowerCase() === account?.toLowerCase();
                return (
                  <div key={index} className="py-2">
                    {isUser ? `${originalIndex + 1} (You)` : originalIndex + 1}
                  </div>
                );
              })}
            </th>
            <th>Account
              {sortedAndHighlightedContenders.map((contender, index) => {
                const isUser = contender.owner.toLowerCase() === account?.toLowerCase();
                return (
                  <div key={index} className={`py-2 ${isUser ? "font-bold" : ""}`}>
                    {truncateAddress(contender.owner)}
                  </div>
                );
              })}
            </th>
            <th>ROI
              {sortedAndHighlightedContenders.map((contender, index) => {
                const isUser = contender.owner.toLowerCase() === account?.toLowerCase();
                return (
                  <div key={index} className={`py-2 ${isUser ? "font-bold" : ""}`}>
                    {(contender.roi * 100).toFixed(2)}%
                  </div>
                );
              })}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
}
