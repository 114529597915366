import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { getContract } from "config/contracts";
import { hashedPositionKey } from "config/dataStore";
import {
  PendingPositionUpdate,
  PositionDecreaseEvent,
  PositionIncreaseEvent,
  useSyntheticsEvents,
} from "context/SyntheticsEvents";
import { useMulticall } from "lib/multicall";
import { getByKey } from "lib/objects";
import { FREQUENT_MULTICALL_REFRESH_INTERVAL } from "lib/timeConstants";

import { ContractMarketPrices, MarketsData, getContractMarketPrices } from "../markets";
import { TokensData } from "../tokens";
import { Position, PositionsData } from "./types";
import { getPositionKey, parsePositionKey } from "./utils";

import ProxyFactory from "sdk/abis/ProxyFactory.json";
import SyntheticsReader from "sdk/abis/SyntheticsReader.json";

const MAX_PENDING_UPDATE_AGE = 600 * 1000; // 10 minutes

type PositionsResult = {
  positionsData?: PositionsData;
  allPossiblePositionsKeys?: string[];
  error?: Error;
};

export function usePositions(
  chainId: number,
  p: {
    marketsData?: MarketsData;
    tokensData?: TokensData;
    account: string | null | undefined;
  }
): PositionsResult {
  const { marketsData, tokensData, account } = p;
  const [disableBatching, setDisableBatching] = useState(true);
  const address = "0x8dF059f09E24E29DD1C88e8cc35cd6593cF7d73b";
  const proxyAddresses = getProxyAddresses(chainId, account);
  // Get keys and prices for each proxy
  // const proxyKeysAndPrices = proxyAddresses?.map(proxyAddress =>
  //   useKeysAndPricesParams({
  //     marketsData,
  //     tokensData,
  //     account: proxyAddress, // Use proxy address instead of account
  //   })
  // ) || [];

  const keysAndPrices = useKeysAndPricesParams({
    marketsData,
    tokensData,
    proxyAddresses, //@note we need to fetch this for all proxies
  });

  const {
    data: positionsData,
    error: positionsError,
    isLoading,
  } = useMulticall(chainId, "usePositionsData", {
    key: account && proxyAddresses?.length ? [proxyAddresses] : null,

    refreshInterval: FREQUENT_MULTICALL_REFRESH_INTERVAL,
    clearUnusedKeys: true,
    keepPreviousData: true,
    disableBatching,

    request: () => ({
      reader: {
        contractAddress: getContract(chainId, "SyntheticsReader"),
        abi: SyntheticsReader.abi,
        calls: proxyAddresses?.reduce(
          (acc, proxyAddress) => {
            acc[`positions_${proxyAddress}`] = {
              methodName: "getAccountPositionInfoList",
              params: [
                getContract(chainId, "DataStore"),
                getContract(chainId, "ReferralStorage"),
                proxyAddress,
                keysAndPrices.marketsKeys,
                keysAndPrices.marketsPrices,
                ethers.ZeroAddress,
                0,
                1000,
              ],
            };
            return acc;
          },
          {} as Record<string, any>
        ),
      },
    }),
    parseResponse: (res) => {
      return Object.entries(res.data.reader).reduce((positionsMap: PositionsData, [key, value]) => {
        const proxyAddress = key.split("_")[1];
        const positions = value.returnValues;

        positions.forEach((positionInfo: any) => {
          const { position, fees, basePnlUsd } = positionInfo;
          const { addresses, numbers, flags, data } = position;
          const { account: proxyAccount, market: marketAddress, collateralToken: collateralTokenAddress } = addresses;

          // Empty position
          if (numbers.increasedAtTime == 0n) {
            return positionsMap;
          }

          const positionKey = getPositionKey(proxyAccount, marketAddress, collateralTokenAddress, flags.isLong);
          const contractPositionKey = hashedPositionKey(
            proxyAccount,
            marketAddress,
            collateralTokenAddress,
            flags.isLong
          );

          positionsMap[positionKey] = {
            key: positionKey,
            contractKey: contractPositionKey,
            account: p.account!,
            // proxyAddress,
            marketAddress,
            collateralTokenAddress,
            sizeInUsd: numbers.sizeInUsd,
            sizeInTokens: numbers.sizeInTokens,
            collateralAmount: numbers.collateralAmount,
            increasedAtTime: numbers.increasedAtTime,
            decreasedAtTime: numbers.decreasedAtTime,
            isLong: flags.isLong,
            pendingBorrowingFeesUsd: fees.borrowing.borrowingFeeUsd,
            fundingFeeAmount: fees.funding.fundingFeeAmount,
            claimableLongTokenAmount: fees.funding.claimableLongTokenAmount,
            claimableShortTokenAmount: fees.funding.claimableShortTokenAmount,
            pnl: basePnlUsd,
            positionFeeAmount: fees.positionFeeAmount,
            traderDiscountAmount: fees.referral.traderDiscountAmount,
            uiFeeAmount: fees.ui.uiFeeAmount,
            data,
          };

          return positionsMap;
        });

        return positionsMap;
      }, {} as PositionsData);
    },
  });

  useEffect(() => {
    if (positionsData && disableBatching) {
      setDisableBatching(false);
    }
  }, [disableBatching, positionsData]);

  const optimisticPositionsData = useOptimisticPositions({
    positionsData: positionsData,
    allPositionsKeys: keysAndPrices?.allPositionsKeys,
    isLoading,
  });

  return {
    positionsData: optimisticPositionsData,
    error: positionsError,
  };
}

export function getProxyAddresses(chainId: number, account: string | null | undefined) {
  const { data: proxyAddresses } = useMulticall(chainId, "useProxyAddresses", {
    key: account ? [account] : null,
    request: () => ({
      proxyFactory: {
        contractAddress: getContract(chainId, "ProxyFactory"),
        abi: ProxyFactory.abi,
        calls: {
          proxies: {
            methodName: "getProxiesOf",
            params: [account],
          },
        },
      },
    }),
    parseResponse: (res) => {
      return res.data.proxyFactory.proxies.returnValues as string[];
    },
  });

  return proxyAddresses || [];
}
function useKeysAndPricesParams(p: {
  proxyAddresses: string[] | undefined;
  marketsData: MarketsData | undefined;
  tokensData: TokensData | undefined;
}) {
  const { proxyAddresses, marketsData, tokensData } = p;

  return useMemo(() => {
    const values = {
      allPositionsKeys: [] as string[],
      marketsPrices: [] as ContractMarketPrices[],
      marketsKeys: [] as string[],
    };

    if (!proxyAddresses?.length || !marketsData || !tokensData) {
      return values;
    }

    const markets = Object.values(marketsData);

    for (const market of markets) {
      const marketPrices = getContractMarketPrices(tokensData, market);

      if (!marketPrices || market.isSpotOnly) {
        continue;
      }

      values.marketsKeys.push(market.marketTokenAddress);
      values.marketsPrices.push(marketPrices);

      const collaterals = market.isSameCollaterals
        ? [market.longTokenAddress]
        : [market.longTokenAddress, market.shortTokenAddress];

      // Generate keys for all proxies
      for (const proxyAddress of proxyAddresses) {
        for (const collateralAddress of collaterals) {
          for (const isLong of [true, false]) {
            const positionKey = getPositionKey(proxyAddress, market.marketTokenAddress, collateralAddress, isLong);
            values.allPositionsKeys.push(positionKey);
          }
        }
      }
    }

    return values;
  }, [proxyAddresses, marketsData, tokensData]);
}

export function useOptimisticPositions(p: {
  positionsData: PositionsData | undefined;
  allPositionsKeys: string[] | undefined;
  isLoading: boolean;
}): PositionsData | undefined {
  const { positionsData, allPositionsKeys, isLoading } = p;
  const { positionDecreaseEvents, positionIncreaseEvents, pendingPositionsUpdates } = useSyntheticsEvents();

  return useMemo(() => {
    if (!allPositionsKeys || isLoading) {
      return undefined;
    }

    return allPositionsKeys.reduce((acc, key) => {
      const now = Date.now();

      const lastIncreaseEvent = positionIncreaseEvents
        ? positionIncreaseEvents.filter((e) => e.positionKey === key).pop()
        : undefined;
      const lastDecreaseEvent = positionDecreaseEvents
        ? positionDecreaseEvents.filter((e) => e.positionKey === key).pop()
        : undefined;

      const pendingUpdate =
        pendingPositionsUpdates?.[key] && (pendingPositionsUpdates[key]?.updatedAt ?? 0) + MAX_PENDING_UPDATE_AGE > now
          ? pendingPositionsUpdates[key]
          : undefined;

      let position: Position;

      if (getByKey(positionsData, key)) {
        position = { ...getByKey(positionsData, key)! };
      } else if (pendingUpdate && pendingUpdate.isIncrease) {
        position = getPendingMockPosition(pendingUpdate);
      } else {
        return acc;
      }

      if (
        lastIncreaseEvent &&
        lastIncreaseEvent.increasedAtTime > position.increasedAtTime &&
        lastIncreaseEvent.increasedAtTime > (lastDecreaseEvent?.decreasedAtTime || 0)
      ) {
        position = applyEventChanges(position, lastIncreaseEvent);
      } else if (
        lastDecreaseEvent &&
        lastDecreaseEvent.decreasedAtTime > position.decreasedAtTime &&
        lastDecreaseEvent.decreasedAtTime > (lastIncreaseEvent?.increasedAtTime || 0)
      ) {
        position = applyEventChanges(position, lastDecreaseEvent);
      }

      if (
        pendingUpdate &&
        ((pendingUpdate.isIncrease && pendingUpdate.updatedAtBlock > position.increasedAtTime) ||
          (!pendingUpdate.isIncrease && pendingUpdate.updatedAtBlock > position.decreasedAtTime))
      ) {
        position.pendingUpdate = pendingUpdate;
      }

      if (position.sizeInUsd > 0) {
        acc[key] = position;
      }

      return acc;
    }, {} as PositionsData);
  }, [
    allPositionsKeys,
    isLoading,
    pendingPositionsUpdates,
    positionDecreaseEvents,
    positionIncreaseEvents,
    positionsData,
  ]);
}

function applyEventChanges(position: Position, event: PositionIncreaseEvent | PositionDecreaseEvent) {
  const nextPosition = { ...position };

  nextPosition.sizeInUsd = event.sizeInUsd;
  nextPosition.sizeInTokens = event.sizeInTokens;
  nextPosition.collateralAmount = event.collateralAmount;
  nextPosition.pendingBorrowingFeesUsd = 0n;
  nextPosition.fundingFeeAmount = 0n;
  nextPosition.claimableLongTokenAmount = 0n;
  nextPosition.claimableShortTokenAmount = 0n;
  nextPosition.pendingUpdate = undefined;
  nextPosition.isOpening = false;

  // eslint-disable-next-line local-rules/no-logical-bigint
  if ((event as PositionIncreaseEvent).increasedAtTime) {
    nextPosition.increasedAtTime = (event as PositionIncreaseEvent).increasedAtTime;
  }

  // eslint-disable-next-line local-rules/no-logical-bigint
  if ((event as PositionDecreaseEvent).decreasedAtTime) {
    nextPosition.decreasedAtTime = (event as PositionDecreaseEvent).decreasedAtTime;
  }

  return nextPosition;
}

export function getPendingMockPosition(pendingUpdate: PendingPositionUpdate): Position {
  const { account, marketAddress, collateralAddress, isLong } = parsePositionKey(pendingUpdate.positionKey);

  return {
    key: pendingUpdate.positionKey,
    contractKey: hashedPositionKey(account, marketAddress, collateralAddress, isLong),
    account,
    marketAddress,
    collateralTokenAddress: collateralAddress,
    isLong,
    sizeInUsd: pendingUpdate.sizeDeltaUsd ?? 0n,
    collateralAmount: pendingUpdate.collateralDeltaAmount ?? 0n,
    sizeInTokens: pendingUpdate.sizeDeltaInTokens ?? 0n,
    increasedAtTime: pendingUpdate.updatedAtBlock,
    decreasedAtTime: 0n,
    pendingBorrowingFeesUsd: 0n,
    fundingFeeAmount: 0n,
    claimableLongTokenAmount: 0n,
    claimableShortTokenAmount: 0n,
    positionFeeAmount: 0n,
    uiFeeAmount: 0n,
    pnl: 0n,
    traderDiscountAmount: 0n,
    data: "0x",

    isOpening: true,
    pendingUpdate: pendingUpdate,
  };
}
